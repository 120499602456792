@import '~antd/dist/antd.css';
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  overflow-y: scroll;
}

body {
  background: url('./assets/bg.jpg') no-repeat center center fixed;
  font-family: 'Roboto', sans-serif;
  background-size: cover;
  min-height: 101%;
}
.body {
  background: url('./assets/bg.jpg') no-repeat center center fixed;
  font-family: 'Roboto', sans-serif;
  background-size: cover;
  min-height: 101%;
}
.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff !important;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff !important;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ffffff !important;
}
.form-control {
  outline: none;
  border: 1px solid #28696d;
  
}
.form-control:focus {
  border-color: #28696d;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px #28696d;
}
.text-boston {
  color: #28696d;
}
